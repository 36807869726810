import java from '../assets/img/java.svg';
import jakartaee from '../assets/img/jakartaee.png';
import cplus from '../assets/img/c++.svg';
import html5 from '../assets/img/html5.svg';
import javascript from '../assets/img/javascript.svg';
import sql from '../assets/img/sql.png';
import css from '../assets/img/css.svg';
import react from '../assets/img/react.svg';
import nodejs from '../assets/img/nodejs.svg';
import bootstrap from '../assets/img/bootstrap.svg';
import mysql from '../assets/img/mysql.png';
import mongodb from '../assets/img/mongodb.svg';
import firebase from '../assets/img/firebase.svg';
import git from '../assets/img/git.svg';
import springboot from '../assets/img/springboot.svg';


import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <section className="skill" id="skills">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="skill-bx wow zoomIn">
                            <h2>Skills</h2>
                            <Carousel responsive={responsive} infinite={false} className="owl-carousel owl-theme skill-slider">


                                <div className="container">
                                    <div class="row">
                                        <p>Languages</p>
                                        <div className="col-sm">
                                            <img style={{ width: '50px' }} src={java} alt="Image" />
                                            <h5>Java SE</h5>
                                        </div>
                                        <div className="col-sm">
                                            <img style={{ width: '50px' }} src={jakartaee} alt="Image" />
                                            <h5>Java EE</h5>
                                        </div>
                                        <div className="col-sm">
                                            <img style={{ width: '50px' }} src={cplus} alt="Image" />
                                            <h5>C++</h5>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div className="col-sm">
                                            <img style={{ width: '50px' }} src={html5} alt="Image" />
                                            <h5>Html</h5>
                                        </div>
                                        <div className="col-sm">
                                            <img style={{ width: '50px' }} src={sql} alt="Image" />
                                            <h5>Sql</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div className="col-sm">
                                            <img style={{ width: '50px' }} src={javascript} alt="Image" />
                                            <h5>JavaScript</h5>
                                        </div>
                                        <div className="col-sm">

                                        </div>
                                    </div>
                                </div>
                                <div className="container">
                                    <div class="row">
                                        <p>Web Technologies</p>
                                        <div className="col-sm">
                                            <img style={{ width: '50px' }} src={css} alt="Image" />
                                            <h5>CSS</h5>
                                        </div>
                                        <div className="col-sm">
                                            <img style={{ width: '50px' }} src={react} alt="Image" />
                                            <h5>React</h5>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div className="col-sm">
                                            <img style={{ width: '50px' }} src={nodejs} alt="Image" />
                                            <h5>Node.js</h5>
                                        </div>
                                        <div className="col-sm">
                                            <img style={{ width: '50px' }} src={bootstrap} alt="Image" />
                                            <h5>BootStrap</h5>
                                        </div>
                                    </div>

                                </div>
                                <div className="container">
                                    <div class="row">
                                        <p>Framework/Technologies</p>
                                        <div className="col-sm">
                                            <img style={{ width: '50px' }} src={springboot} alt="Image" />
                                            <h5>SpringBoot</h5>
                                        </div>
                                        <div className="col-sm">
                                            <img style={{ width: '50px' }} src={git} alt="Image" />
                                            <h5>git</h5>
                                        </div>
                                    </div>

                                </div>
                                <div className="container">
                                    <div class="row">
                                        <p>Databases</p>
                                        <div className="col-sm">
                                            <img style={{ width: '50px' }} src={mysql} alt="Image" />
                                            <h5>mySql</h5>
                                        </div>
                                        <div className="col-sm">
                                            <img style={{ width: '50px' }} src={mongodb} alt="Image" />
                                            <h5>MongoDb</h5>
                                        </div>
                                        <div className="col-sm">
                                            <img style={{ width: '50px' }} src={firebase} alt="Image" />
                                            <h5>Firebase</h5>
                                        </div>
                                    </div>

                                </div>



                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            <img style={{ width: '50px' }} className="background-image-left" src={colorSharp} alt="Image" />
        </section>
    )
}

export default Skills;