import { Container, Nav, Navbar } from 'react-bootstrap';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import logo from '../assets/img/logo.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import gmail from '../assets/img/gmail.svg';
import React from 'react';
import { useHistory } from 'react-router-dom';

import '../App.css';

import { useState, useEffect } from "react";

function NavBar() {
  const [activeLink, setActiveLink] = useState('home');
  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  return (
    <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="#home">{/* <img src={logo} alt="LOGO" /> */}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" expanded={true}>
          <Nav className="me-auto">
            <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Piyush Ramteke</Nav.Link>
            <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
            <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
            <Nav.Link href="#project" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
            <Nav.Link target='_blank' href="https://drive.google.com/file/d/1xAeErNluaQsZODyPpll_OksgBakJueC9/view?usp=drive_link" className={activeLink === 'resume' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('resume')}> Resume  <ArrowRightCircle size={25} />  </Nav.Link>
          </Nav>

          <span className='navbar-text'>
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/piyushramteke" target="_blank"><img src={navIcon1} alt="" /></a>
              <a href="https://github.com/piyush4878" target="_blank"><img src={navIcon2} alt="" /></a>
              <a href="mailto:piyushramteke18@gmail.com"><img src={gmail} alt="" /></a>
            </div>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
