import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import headerImg from "../assets/img/header-img.svg";

import '../App.css';


export const Banner = () => {
    return (
        <section className='banner' id='home'>

            <Container>
                <Row className="aligh-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <span className="tagline">Welcome to my Portfolio</span>
                        <h1 className="my-name">Hi! I'm Piyush Ramteke</h1>
                        <p>A meticulous and organized individual seeking an Entry-level position in the field of Project Management. Skilled at developing reports, analyzing data, and identifying solutions. Strong ability to handle complex projects. Innovative, creative, and willing to contribute ideas and learn new things.</p>
                   


                    </Col>
                    <Col xs={12} md={6} xl={5}>

                        <img src={headerImg} alt="Header Img" />

                    </Col>
                </Row>
            </Container>





        </section>
    )
}

export default Banner;