import { Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { FaTelegramPlane } from 'react-icons/fa';


function ProjectCard({ title, description, imgUrl , imglink }) {
  return (
    <Col size={12} sm={6} md={4}>

      <Card style={{ width: '18rem' }}>
        <Card.Img variant="top" src={imgUrl} />
        <Card.Body>
          <Card.Title style={{ color: 'black' }}>{title}</Card.Title>
          <Card.Text>
            {description}
          </Card.Text>

          {/* <Button className="btn btn-outline-dark" >   </Button> */}

          <a href={imglink} target="_blank"><FaTelegramPlane /></a>

        </Card.Body>
      </Card>

      {/* <div className="proj-imgbx">
            <img src={imgUrl} />
            <div className="proj-txtx">
              <h4>{title}</h4>
              <span>{description}</span>
            </div>
          </div> */}
    </Col>

  )
}

export default ProjectCard