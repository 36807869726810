import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import piyushramtekelogo from "../assets/img/piyushramtekelogo.gif";
import gmail from '../assets/img/gmail.svg';

export const Footer = () => {
  return (
    <footer className="footer py-3">
      <Container>
        <Row className="align-items-center text-center">
          {/* <MailchimpForm /> */}
          <Col xs={12} md={6} className="mb-3 mb-md-0">
            <img src={piyushramtekelogo} alt="Logo" className="mx-auto" />
          </Col>
          <Col xs={12} md={6}>
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/piyushramteke/" target="_blank"><img src={navIcon1} alt="Icon" /></a>
              <a href="https://github.com/piyush4878" target="_blank"><img src={navIcon2} alt="Icon" /></a>
              <a href="mailto:piyushramteke18@gmail.com"><img src={gmail} alt="" /></a>
            </div>
            <p>Copyright 2023. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
