import React from 'react'
import ProjectCard from './ProjectCard';
import ProjectCard2 from './ProjectCard2';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import todoApp from '../assets/img/todoApp.png';
import colorSharp2 from "../assets/img/color-sharp2.png";
import CineStar from "../assets/img/CineStar.png";
import shopNship from '../assets/img/shopNship.png';
import colorSharp from "../assets/img/color-sharp.png";

function Projects() {

    const projects = [
        {
            title: " Film Review & Recommendation System - CineStar",
            description: " Spring Boot 3 / Spring MVC, MySQL/Clever Cloud DB with JDBC/Hibernate, Tomcat 10 server, and Eclipse 2023 IDE ",
            imgUrl: CineStar,
            imglink: "https://github.com/frenz659/CineStar"
        },
        {
            title: "Ecom Web Application - ShopNShip",
            description: " HTML, CSS, JavaScript, Material UI, React.js, React bootstrap and Firebase",
            imgUrl: shopNship,
            imglink: "https://shopnship-e749f.web.app/"
        },
        {
            title: "Todo Web App - SimpleNotes",
            description: " HTML, CSS, JavaScript, Material UI, React.js, React bootstrap and Firebase",
            imgUrl: todoApp,
            imglink: "https://todo-app-2c002.web.app/"
        },


    ];


    return (
        <section className="project" id='project'>

            <Container>

                <Row>
                    <Col>


                        <Tab.Container id="projects-tabs" defaultActiveKey="first">
                            <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center flex-column flex-md-row" id="pills-tab">

                                <Nav.Link eventKey="first">  <h4 className='my-3'>Projects</h4></Nav.Link>

                                {/* <Nav.Item>
                                    <Nav.Link eventKey="second">-</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">-</Nav.Link>
                                </Nav.Item> */}
                            </Nav>

                            <Tab.Content id="slideInUp" >
                                <Tab.Pane eventKey="first">
                                    <Row>
                                        {
                                            projects.map((project, index) => {
                                                return (
                                                    <ProjectCard2
                                                        key={index}
                                                        {...project}
                                                    />
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>
                                {/* <Tab.Pane eventKey="section">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                                </Tab.Pane> */}
                            </Tab.Content>
                        </Tab.Container>

                    </Col>

                </Row>
            </Container>

      

                <img style={{ width: '100px' }} className="background-image-left" src={colorSharp} alt="Image" />

     
        </section>
    )
}

export default Projects