import { Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { FaTelegramPlane } from 'react-icons/fa';


function ProjectCard2({ title, description, imgUrl, imglink }) {
    return (
        <section className="container" id=''>

            <div class="card mb-3 " style={{ "maxWidth": "1000px" }}>
                <div class="row no-gutters">
                    <div class="col-md-4">
                        <img src={imgUrl} class="card-img" alt="..." />
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            
                            <h5 class="card-title " style={{color : "black"}}>{title}</h5>
                            <p class="card-text">{description}</p>

                            <a href={imglink} target="_blank"><FaTelegramPlane /></a>
                        </div>
                    </div>
                </div>
            </div>



        </section>

    )
}

export default ProjectCard2